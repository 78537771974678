<template>
<div class="search pa-4">
    <v-app-bar app flat color="primary" dark extended extension-height="72">

        <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon @click="$router.go(-1)" v-else>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="text-subtitle-1 font-weight-medium">Pets for adoption</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="filterDialog = true">
            <v-icon>$vuetify.icons.filter-icon</v-icon>
        </v-btn>

        <template v-slot:extension v-if="pet_type != ''">
            <div @click="filterDialog = true" >

              <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="$store.getters.typeDetails(pet_type)">
                    {{$store.getters.typeDetails(pet_type).type}}
                </v-chip>
                
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary">
                    {{gender == '' ? 'Any gender' : (gender == 'M' ? 'Males' : 'Females')}}
                </v-chip>

                 <v-chip class="ma-1" small color="#f0e7ff" text-color="primary">
                    {{age == 'B' ? 'Baby' : (age == 'Y' ? 'Young' : (age == 'A' ? 'Adult' : ( age == 'S' ? 'Senior' : 'Any age')))}}
                </v-chip>
                
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="breed">
                    {{breedName}}
                </v-chip>
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary">
                    Within {{distance}} miles
                </v-chip>
                <v-chip class="ma-1" small color="#f0e7ff" text-color="primary" v-if="sortBy">
                    {{ sortBy == 'distance' ? 'Sort by Nearest First' : ''}}
                    {{ sortBy == 'last_activity' ? 'Sort by Last Activity' : ''}}
                </v-chip>
            </div>

        </template>

    </v-app-bar>

    <div v-if="this.id == ''" class="text-center pt-16 mt-16">
      <div>Please let us know your preferences so that we can find a perfect pet for you</div>

      <v-btn class="mt-10" text color="primary" @click="filterDialog = true">MY PREFERENCES</v-btn>
    </div>
    <div v-else>

    <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item,index) in this.$store.getters.searchResults" :key="index" class="px-4 pb-2">
            <pet-card :pet="item"></pet-card>
        </v-col>
    </v-row>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">

        <div slot="no-more">
            <v-row class="pt-6" justify="center">
                <v-col cols="12" md="6" class="py-12 px-6 text-center">

                    <div class="text-center">
                            <p class="pt-4 text-subtitle-2">Couldn't find a suitable pet? 🙁</p>
                            <!-- <v-icon size="80" color="#eee" class="pb-4">mdi-emoticon-sad-outline</v-icon> -->
                            <p class="pb-2 text-caption">You may try changing the <a href="javascript:void(0);" @click="filterDialog = true">filters</a>.</p>

                            <v-alert icon="mdi-email-receive-outline" prominent dark color="primary" >
                                <span class="text-caption">We will send more matches based on your filters to your inbox as we find them.</span>
                            </v-alert>
                        </div>

                        <div class="text-center">
                        
                        <v-card class="mild-shadow mt-12">
                        <v-card-text class="pa-6">
                        
                        <div class="text-h6 font-weight-bold pb-4 black--text">
                            Install Petmeetly
                        </div>
                        <div class="text-caption pb-4">
                            Save Petmeetly on your device for quick and easy access when you're on the go.
                        </div>

                        <v-row>
                            <v-col cols="12">
                                <a href="https://apps.apple.com/app/apple-store/id6478047015?pt=118572154&ct=Web%20App%20Referral&mt=8" target="_blank">
                                <v-img src='@/assets/logo/appstore-256x256.png' max-width="180"  class="mx-auto"></v-img>
                            </a>
                            </v-col>
                            <!-- <v-col cols="12" sm="6">
                                <a href="https://apps.apple.com/in/app/petmeetly-pets-match-finder/id6478047015" target="_blank">
                                <v-img src='@/assets/logo/googleplay-badge-256.png' max-width="200" class="mx-auto"></v-img>
                                </a>
                            </v-col> -->
                        </v-row>


                        </v-card-text>

                        </v-card>

                    </div>

                </v-col>
            </v-row>

        </div>
        <div slot="no-results">
            <v-row class="pt-6" align="center" justify="center">
                <v-col cols="12" md="6" class="pa-12 text-center">
                    <v-icon size="120" color="#eee">mdi-heart-broken</v-icon>
                    <div class="pt-4">
                        <p>We're sorry. We couldn't find a pet meeting your criteria now.</p>
                        <p>Watch your inbox. We will let you know when we find a few matching pets for you.</p>
                        <p>You may also try changing the <a href="javascript:void(0);" @click="filterDialog = true">filters</a>.</p>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div slot="spinner">
            <v-row>
                <v-col cols="12" justify="center" align="center" class="mt-5">
                    <!-- <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular> -->
                    <img :src="$loaderImageUrl" height="100" alt="Loading..." />
                </v-col>

            </v-row>
        </div>

    </infinite-loading>
    </div>
    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>


    <v-dialog v-model="filterDialog" :fullscreen="$vuetify.breakpoint.xsOnly" persistent scrollable origin="top right" max-width="450" transition="dialog-transition">
        <v-card>
          <v-overlay :value="dataLoading">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
            <div>
                <v-toolbar flat>
                    <v-toolbar-title class="toolbar-title">
                        <span class="subtitle-custom"></span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon @click.native="filterDialog=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
            </div>

            <v-card-text class="pt-10 search-filter">

                <v-row>

                    <v-col cols="12" class="px-4 py-0 pb-6">
                        <div class="primary--text pb-2 text-subtitle-1 font-weight-medium">Type of pet</div>
                        <v-item-group v-model="pet_type" mandatory @change="breed=''">
                            <v-row no-gutters>
                                <v-col cols="3" class="d-flex align-center justify-center pa-1" v-for="(item,index) in this.$store.getters.petTypes" :key="index">
                                    <v-item v-slot="{ active, toggle }" :value="item.xid">
                                        <v-sheet @click="toggle" height="70" width="70" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                            <div class="text-center pa-1">
                                                <v-icon size="28">{{'$vuetify.icons.'+item.icon}}</v-icon>
                                            </div>
                                            <div class="text-center">
                                                {{item.type}}
                                            </div>
                                        </v-sheet>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>
                    </v-col>

                    <v-col cols="12" class="px-4 pb-2">
                        <div class="primary--text text-subtitle-1 font-weight-medium">Gender</div>
                        <v-radio-group v-model="gender" row>
                            <v-radio label="Male" value="M"></v-radio>
                            <v-radio label="Female" value="F"></v-radio>
                            <v-radio label="Any" value=""></v-radio>
                        </v-radio-group>
                    </v-col>

                    <v-col cols="12" class="px-4 pb-2">
                        <div class="primary--text text-subtitle-1 font-weight-medium">Age</div>

                        <v-radio-group v-model="age" row>
                            <v-radio label="Any age" value="" class="py-2"></v-radio>
                            <v-radio label="Baby" value="B" class="py-2"></v-radio>
                            <v-radio label="Young" value="Y" class="py-2"></v-radio>
                            <v-radio label="Adult" value="A" class="py-2"></v-radio>
                            <v-radio label="Senior" value="S" class="py-2"></v-radio>
                        </v-radio-group>

                        <!-- <v-slider v-model="age_idx" :tick-labels="ticksLabels" track-color="#ddd" track-fill-color="primary" :max="4" step="1" ticks="always" tick-size="8"></v-slider>
 -->
                    </v-col>

                    <v-col cols="12" class="px-4 pb-6">
                        <div class="primary--text pb-2 text-subtitle-1 font-weight-medium">Breed <span class="grey--text text-caption">(If you have a preference)</span></div>
                        <div>

                            <v-autocomplete v-model="breed" solo flat hide-no-data hide-details clearable :filter="filterObject" :items="typeBreeds" item-text="breed" item-value="xid" return-object label="Select breed">

                                <template v-slot:item="data">
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.breed"></v-list-item-title>
                                        <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>

                            </v-autocomplete>
                        </div>

                    </v-col>

                    <v-col cols="12" class="px-4 py-0 pb-6">
                    <div class="primary--text pb-2 text-subtitle-1 font-weight-medium">Location</div>

                    <v-alert v-model="locationPicked" class="pl-0">
                        <v-row align="center">
                            <v-col class="grow">
                                {{location ? location.description : ''}}
                            </v-col>
                            <v-col class="shrink">
                                <v-btn icon @click="clearLocation">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>

                    <div v-show="!locationPicked">

                        <v-autocomplete v-model="location" :items="searchResults" :search-input.sync="search" flat hide-no-data placeholder="Please type your locality" item-text="description" item-value="placeId" clearable  hint="So that we can find matches closer to you" persistent-hint prepend-inner-icon="mdi-map-marker-outline" return-object @change="getPlaceDetails" @click:clear="clearLocation"></v-autocomplete>

                        <div class="py-4">
                            <v-btn text small color="primary" @click="getCurrentLocation" :disabled="gettingLocation">
                                <v-icon left>mdi-crosshairs-gps</v-icon> {{gettingLocation ? 'Getting your location' : 'get your current location'}}
                            </v-btn>
                        </div>

                    </div>

                </v-col>

                    <v-col cols="12" class="px-4 py-0 pb-6">
                        <div class="primary--text pb-2 text-subtitle-1 font-weight-medium">Pets within a distance of <span class="font-weight-bold">{{distance}}</span> Miles</div>
                        <v-slider thumb-label v-model="distance" step="10" min="10" max="3000" hide-details color="primary" track-color="#ddd" track-fill-color="primary"></v-slider>
                    </v-col>

                    <v-col cols="12" class="px-4 py-6">
                        <div class="primary--text text-subtitle-1 font-weight-medium">Why are you adopting a pet now?</div>
                        <v-textarea solo flat auto-grow row-height="30" rows="5" counter="300" maxlength="300" placeholder="Please tell us why you would like to adopt a pet. This could help the pet owners to make sure they are sending their pets to the right people." v-model="description"></v-textarea>
                    </v-col>

                    <v-col cols="12" class="px-4 py-2">
                        <div class="primary--text text-subtitle-2 font-weight-600">Sort by</div>
                        <v-radio-group v-model="sortBy" class="mt-2">
                            <v-radio label="Default" value=""></v-radio>
                            <v-radio label="Last Activity" value="last_activity"></v-radio>
                            <v-radio label="Distance" value="distance"></v-radio>
                        </v-radio-group>
                    </v-col>

                </v-row>

            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn block large color="primary" @click="resetAndFilter" :loading="disabled" :disabled="disabled">Search</v-btn>

                <v-spacer></v-spacer>

            </v-card-actions>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
import PetCard from '../components/PetCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
import gmapsInit from '../../plugins/gmaps';
import _ from 'lodash';
export default {
    name: 'SearchAdoptions',

    async mounted() {

        const google = await gmapsInit();
        //this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        this.service = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder();

        if (this.$store.getters.petTypes.length == 0) {
            this.getStaticData();
        }

        this.$store.dispatch('setMyPetID', null);

        if (this.$store.getters.userDetails.xid) 
        {

          if (this.$store.getters.userDetails.preferences) {

            this.$store.dispatch('clearSearchResults');

          //console.log(this.$store.getters.userDetails.preferences);
            let preference = this.$store.getters.userDetails.preferences;

            this.breed = preference.pet_breed
            this.distance = preference.pref_distance
            this.gender = preference.gender || ''
            this.age = preference.age || ''
            this.pet_type = preference.pet_type.xid
            this.id = preference.xid
            this.description = preference.description
            this.sortBy = preference.sort_by


            this.location = {
                description: preference.location_description,
                placeId: preference.place_id,
                state: preference.state,
                country: preference.country,
                zip: preference.zip,
                locality: preference.locality,
            };
            this.locationPicked = true;
            this.cordinates = {
                lat: preference.latitude,
                lng: preference.longitude
            };

        } else {
            this.filterDialog = true
        }

        }
        else
        {
          this.$router.push('/home');
        }

        

    },

    components: {
        PetCard,
        InfiniteLoading
    },

    data() {
        return {
            disabled: false,
            snackbar: false,
            snackbar_text: '',
            preference: {},
            favLoading: false,
            page: 1,
            infiniteId: +new Date(),
            filterDialog: false,
            breed: '',
            distance: 100,
            dataLoading: false,
            gender: '',
            age: '',
            pet_type: '',
            id: '',
            description: '',
            sortBy: '',
            

            ticksLabels: [
                'Any',
                'Baby',
                'Young',
                'Adult',
                'Senior',
            ],


            location: {
            description: '',
            placeId: '',
            state: '',
            country: '',
            xip:'',
            locality:'',
            },
            searchResults: [],
            service: null,
            geocoder: null,
            sessionToken: null,
            search: null,
            cordinates: null,
            locationPicked: false,

            gettingLocation: false,
            }
    },

    computed: {

        typeBreeds() {
            let petTypes = this.$store.getters.petTypes;
            let selectedPetType = this.pet_type;

            let filteredItem = petTypes.filter(item => {
                return item.xid == selectedPetType
            });

            if (filteredItem.length > 0)
                return filteredItem[0].breeds;
            else
                return [];
        },

       breedName() {
          return this.breed ? _.truncate(this.breed.breed, {
            'length': 20,
          }) : ''
        }

    },

    watch: {

        search(val) {
            if (val != '' && val !== null)
                {
                    if((val.length > 3) && (val.length % 2 == 0)) {
                    this.service.getPlacePredictions({
                        input: val,
                        //sessionToken: this.sessionToken,
                        types: ['geocode']
                    }, this.displaySuggestions)
                }
            } 
        }

    },

    methods: {

      truncate(str, n){
        return (str.length > n) ? str.substr(0, n-1) + '&hellip;' : str;
      },

        filterObject(item, queryText, itemText) {
            return (
                item.breed.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 /* ||
                item.description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 */
            );
        },

        getStaticData() {
            this.dataLoading = true;
            this.$store.dispatch('getStaticData').then(() => {
                    this.dataLoading = false;
                })
                .catch(err => {
                    //console.log(err);
                    this.dataLoading = false;
                    this.snackbar_text = "Oops, there is an error loading details for the page";
                    this.snackbar = true;
                });
        },

        resetAndFilter() {

            if (!this.location || this.location.description == '') {
                this.snackbar_text = "Please pick your location";
                this.snackbar = true;
            } else if(this.description == '') {
                this.snackbar_text = "Please tell us why you would like to adopt a pet";
                this.snackbar = true;
            } else
            {

              this.disabled = true;
              let id = this.id
              let breed =  JSON.stringify(this.breed)
              let distance = this.distance
              let age = this.age
              let gender = this.gender
              let pet_type = this.pet_type
              let description = this.description
              let location =  JSON.stringify(this.location)
              let cordinates =  JSON.stringify(this.cordinates)
              let sort_by = this.sortBy

              this.$store.dispatch('setPetPreferences', {
                  id,
                  breed,
                  distance,
                  age,
                  gender,
                  pet_type,
                  description,
                  location,
                  cordinates,
                  sort_by
              }).then((resp) => {

                this.id = resp.xid;
                this.filterDialog = false;
                this.page = 1;
                this.$store.dispatch('clearSearchResults');
                this.infiniteId += 1;
                this.disabled = false;

              });
            }

        },

        infiniteHandler($state) {

        //If contact details are not verified prompt it

        if(!this.$store.getters.userDetails.email_verified || !this.$store.getters.userDetails.phone_verified)
        {
            this.showVerifyContactPop();
        }



          if(this.id != '')
          {
            let id = this.id
            let breed = JSON.stringify(this.breed)
            let distance = this.distance
            let age = this.age
            let gender = this.gender
            let pet_type = this.pet_type
            let page = this.page
            let location = JSON.stringify(this.location)
            let cordinates = JSON.stringify(this.cordinates)
            let sortBy = this.sortBy

            window.gtag('event', 'search_adoptions', {
                event_category: 'Search',
                event_label: 'Search Adoptions',
            });

            this.$store.dispatch('searchAdoptions', {
                    id,
                    breed,
                    distance,
                    age,
                    gender,
                    pet_type,
                    page,
                    location,
                    cordinates,
                    sortBy
                })
                .then((resp) => {
                    if (resp > 0) {
                        this.page += 1;
                        $state.loaded();

                        window.gtag('event', 'search_adoptions_results', {
                            event_category: 'Search',
                            event_label: 'Search Adoptions',
                        });

                    } else {
                        $state.complete();
                    }
                })
                .catch(err => {
                    $state.complete();
                });
          }

        },

        clearLocation() {

            //console.log('clear location');
            this.location = {
                description: '',
                placeId: '',
                state: '',
                country: '',
                zip:'',
                locality:'',
            };
            this.cordinates = null;
            this.searchResults = [];
            this.locationPicked = false;

            //this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
        },

        /* MapsInit() {
            console.log('Initializing map');
            this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
            this.service = new window.google.maps.places.AutocompleteService();
            this.geocoder = new window.google.maps.Geocoder();
        }, */
        displaySuggestions(predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                this.searchResults = []
                return
            }
            this.searchResults = predictions.map(prediction => ({
                description: prediction.description,
                placeId: prediction.place_id
            }))
        },

        getPlaceDetails() {
            if (this.location && this.location.placeId != '') {
                //console.log(this.location.placeId);
                this.geocoder.geocode({
                    placeId: this.location.placeId
                }, (results, status) => {
                    if (status == window.google.maps.GeocoderStatus.OK) {

                        let res = results[0];

                        for (var i = 0; i < res.address_components.length; i++) {
                            var addressType = res.address_components[i].types[0];
                            var addressType2 = res.address_components[i].types[1];

                            if (addressType == 'administrative_area_level_1')
                                this.location.state = res.address_components[i].long_name;
                            else if (addressType == 'country')
                                this.location.country = res.address_components[i].short_name;
                            else if (addressType == 'postal_code')
                                this.location.zip = res.address_components[i].long_name;
                            else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                                this.location.locality = res.address_components[i].long_name;

                        }

                        this.cordinates = {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        };

                        this.locationPicked = true;
                        //console.log(this.cordinates);
                        //this.closeFieldDialog();

                    }
                });
            }
        },

        async getCurrentLocation() {
            this.gettingLocation = true;
            try {

                let location = await this.getLocation();

                //console.log(location);
                this.cordinates = {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude
                };

                this.geocoder.geocode({
                    location: this.cordinates
                }, (results, status) => {
                    if (status == window.google.maps.GeocoderStatus.OK) {

                        //console.log(results[0]);
                        this.gettingLocation = false;

                        let res = results[0];

                        for (var i = 0; i < res.address_components.length; i++) {
                            var addressType = res.address_components[i].types[0];
                            var addressType2 = res.address_components[i].types[1];

                            if (addressType == 'administrative_area_level_1')
                                this.location.state = res.address_components[i].long_name;
                            else if (addressType == 'country')
                                this.location.country = res.address_components[i].short_name;
                            else if (addressType == 'postal_code')
                                this.location.zip = res.address_components[i].long_name;
                            else if (addressType == 'locality' || addressType == 'administrative_area_level_2')
                                this.location.locality = res.address_components[i].long_name;

                        }

                        this.location.description = results[0].formatted_address;
                        this.location.placeId = results[0].place_id;

                        this.searchResults = [{
                            description: results[0].formatted_address,
                            placeId: results[0].place_id
                        }];

                        this.locationPicked = true;

                        //console.log(this.searchResults);

                        //this.closeFieldDialog();

                    }
                });

            } catch (e) {
                this.gettingLocation = false;
                this.errorStr = e.message;
            }

        },

        async getLocation() {

            return new Promise((resolve, reject) => {

                if (!("geolocation" in navigator)) {
                    reject(new Error('Geolocation is not available.'));
                }

                navigator.geolocation.getCurrentPosition(pos => {
                    resolve(pos);
                }, err => {
                    reject(err);
                });

            });
        },

        showVerifyContactPop()
        {
            this.$store.dispatch('showVerifyContactPop', true)
        }
    }
}
</script>
