<template>
    <v-app>
        <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->
        <!-- <v-row no-gutters justify="center">
        <v-col cols="12" sm="8" md="6"> -->
        <top-bar v-if="!noHeader.includes($route.name)" />
        <v-main>
            <router-view />
        </v-main>
        <footer-bar v-if="!noFooter.includes($route.name) && $vuetify.breakpoint.mobile" />
        <!--  </v-col>
    </v-row> -->

        <!-- <premium-inr v-if="this.$store.getters.isLoggedIn && this.$store.getters.userDetails.country == 'IN' &&  this.$store.getters.userDetails.email != 'mail@petmeetly.com'"></premium-inr> -->
        <premium-cf
            v-if="this.$store.getters.isLoggedIn && this.$store.getters.userDetails.country == 'IN'"></premium-cf>
        <premium-fs
            v-if="this.$store.getters.isLoggedIn && this.$store.getters.userDetails.country != 'IN'"></premium-fs>
        <premium-stripe
            v-if="this.$store.getters.isLoggedIn && this.$store.getters.userDetails.country != 'IN'"></premium-stripe>

        <direct-connect-stripe
            v-if="this.$store.getters.isLoggedIn && this.$store.getters.userDetails.subscription_route == 'stripe'"></direct-connect-stripe>
        <direct-connect-cf
            v-else-if="this.$store.getters.isLoggedIn && this.$store.getters.userDetails.country == 'IN'"></direct-connect-cf>
        <direct-connect-fs v-else></direct-connect-fs>
        <!--<direct-connect v-else></direct-connect> -->

        <!-- <premium-fs v-if="this.$store.getters.isLoggedIn && this.$store.getters.userDetails.email == 'mail@petmeetly.com'"></premium-fs>
    <premium-inr v-else-if="this.$store.getters.isLoggedIn && this.$store.getters.userDetails.country == 'IN'"></premium-inr>
    <premium v-else></premium>

    <direct-connect-fs v-if="this.$store.getters.isLoggedIn && this.$store.getters.userDetails.email == 'mail@petmeetly.com'"></direct-connect-fs>
    <direct-connect v-else></direct-connect> -->

        <!-- <premium-fs></premium-fs>
    <direct-connect-fs></direct-connect-fs> -->

        <message-credit></message-credit>
        <verify-contact></verify-contact>
        <verify-profile></verify-profile>
        <cookie-policy></cookie-policy>
        <install-prompt></install-prompt>
        <contact-support></contact-support>
        <set-category></set-category>
        

        <v-overlay :value="dataLoading" color="white" opacity="1">

            <v-row align="center" justify="center">

                <v-col cols="12">
                    <v-img src='@/assets/logo/icon-196.png' max-width="196" class="mx-auto"></v-img>
                </v-col>

                <v-col cols="12">
                    <v-img src='@/assets/logo/petmeetly-text-color.png' max-width="200" class="mx-auto"></v-img>
                </v-col>

                <v-col cols="12" class="pa-16">
                    <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                </v-col>
            </v-row>

        </v-overlay>

        <v-dialog v-model="promptUpdate" persistent max-width="450" :retain-focus="false">

            <v-card>

                <!-- <v-toolbar flat color="transparent" max-height="56">

            <v-spacer></v-spacer>

            <v-btn icon @click.native="close">
                <v-icon>mdi-close</v-icon>
            </v-btn> 

        </v-toolbar>-->

                <v-card-text class="text-center pt-8">
                    <div>
                        <v-img src='@/assets/logo/icon-196.png' max-width="90" class="mx-auto"></v-img>
                    </div>
                    <div class="text-h5 py-4">Update Petmeetly</div>
                    <div class="pb-8">
                        A new version is found. <br />we recommend that you update to the latest version.
                    </div>
                    <div>
                        <v-btn color="primary" @click="updateApp">Update</v-btn>
                    </div>
                </v-card-text>


            </v-card>

        </v-dialog>

    </v-app>
</template>

<script>
import TopBar from './components/TopBar.vue'
import FooterBar from './components/FooterBar.vue'
//import Premium from '../views/components/Premium.vue'
import PremiumStripe from '../views/components/PremiumCheckoutStripe.vue'
import PremiumInr from '../views/components/PremiumCheckoutInr.vue'
import PremiumFs from '../views/components/PremiumCheckoutFS.vue'
import PremiumCf from '../views/components/PremiumCheckoutCFsingle.vue' //Now we are loading the single order. once the subscription is ready we will load that instead
//import DirectConnect from '../views/components/DirectConnect.vue'
import DirectConnectStripe from '../views/components/DirectConnectStripe.vue'
import DirectConnectFs from '../views/components/DirectConnectFS.vue'
import DirectConnectCf from '../views/components/DirectConnectCF.vue'
import MessageCredit from '../views/components/MessageCredit.vue'
import CookiePolicy from './components/CookiePolicy.vue';
import InstallPrompt from './components/InstallPrompt.vue'
import VerifyContact from '../views/components/VerifyContact.vue';
import VerifyProfile from '../views/components/VerifyProfile.vue'
import ContactSupport from "../views/static/ContactSupport.vue";
import SetCategory from "../views/components/SetCategory.vue";


export default {
    name: 'App',

    created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.promptUpdate = true;
      });
    }
  },

    mounted() {

        if (this.$store.getters.isLoggedIn) {
            this.dataLoading = true;
            this.$store.dispatch('profile').then(() => {
                this.dataLoading = false;

                if(this.$store.getters.userDetails.latitude != null && (this.$store.getters.userDetails.category == null || this.$store.getters.userDetails.category == ""))
                {
                    this.$store.dispatch('showCategoryPop', true)
                }

            });
            this.$store.dispatch('getConversations').then(() => {
                this.dataLoading = false;
            });

            /* this.interval = setInterval(() => {
                this.$store.dispatch('getConversations');
            }, 180000); */

        } else {
            this.$router.replace('login');
        }

    },

    components: {
        TopBar,
        FooterBar,
        PremiumStripe,
        DirectConnectStripe,
        CookiePolicy,
        InstallPrompt,
        VerifyContact,
        VerifyProfile,
        PremiumInr,
        PremiumFs,
        PremiumCf,
        DirectConnectFs,
        DirectConnectCf,
        MessageCredit,
        ContactSupport,
        SetCategory,
    },

    data: () => ({
        noHeader: ['Pet', 'NewPet', 'EditPet', 'Pets', 'SearchMatches', 'SearchFriends', 'SearchAdoptions', 'SearchAdopters', 'SearchBuyers', 'SearchAvailable', 'Faq', 'Chats', 'Adopter', 'Terms', 'Privacy', 'UnsubscribeEmails', 'QuickReply', 'PetsFeed','PetsFeedMain', 'FoundMatchStatus', 'FoundPetStatus', 'AndroidPremium', 'AndroidDc', 'Messages', 'Favourites', 'Search', 'Account', 'Settings', 'Notifications', 'ArchivedMessages'],
        noFooter: ['Pet', 'NewPet', 'EditPet', 'Pets', 'SearchMatches', 'SearchFriends', 'SearchAdoptions', 'SearchAdopters', 'SearchBuyers', 'SearchAvailable', 'Faq', 'Chats', 'Adopter', 'Terms', 'Privacy', 'UnsubscribeEmails', 'QuickReply', 'PetsFeed','PetsFeedMain', 'FoundMatchStatus', 'FoundPetStatus', 'AndroidPremium', 'AndroidDc'],
        dataLoading: false,
        promptUpdate: false,
    }),

    methods: {
        async updateApp() {
            this.promptUpdate = false;
            await this.$workbox.messageSW({ type: "SKIP_WAITING" });
        },
    },

    beforeDestroy() {
        /* if (this.interval) {
            clearInterval(this.interval);
        } */
    },

};
</script>
