<template>
<v-dialog v-model="this.$store.getters.premiumPop" persistent scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="450" :retain-focus="false">

    <v-card v-show="!showSubscribe">

<!--         <v-toolbar flat color="transparent" max-height="56">

            <v-spacer></v-spacer>

            <v-btn icon @click.native="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>

        </v-toolbar> -->

        <v-card-text class="pt-6">

          

                

                <div class="text-center">
                    <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37" large>mdi-crown</v-icon>
                    <div class="text-overline pt-2 premium-title" style="font-size:.9em !important;">Unlock Premium Benefits </div>
                </div>

                <div class="pa-4 mt-4 mb-8" style="background-color: rgba(0, 31, 63, 0.05); border-radius:10px;">
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">Send/Receive unlimited messages</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">Share contact details</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">Direct connect credits</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4 pb-0">Stand out with our Premium tag</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1" class="pb-0">
                            <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                        </v-col>
                        <v-col cols="11" class="pl-4">Get Featured on Social Media</v-col>
                    </v-row>
                </div>

                <v-item-group v-model="selectedPlan">
                        <v-row>
                            <v-col cols="6" class="d-flex align-center justify-center">
                                <v-item v-slot="{ active, toggle }" value="M">
                                    
                                    <v-sheet @click="toggle" height="135" width="150" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <div class="text-center text-overline">
                                            Monthly
                                        </div>
                                        <div class="text-center text-h6 pa-4 font-weight-bold" v-if="$store.getters.userDetails.country == 'IN'">
                                           &#8377; 499.00
                                        </div>
                                        <div class="text-center text-h6 pa-4 font-weight-bold" v-else>
                                           $19.99
                                        </div>
                                       <!--  <div class="text-center text-caption">
                                            +2 connects*
                                        </div> -->
                                    </v-sheet>
                                   
                                </v-item>
                            </v-col>
                            <v-col cols="6" class="d-flex align-center justify-center">
                                <v-item v-slot="{ active, toggle }" value="Q">
                                    <v-badge right offset-x="80" offset-y="7" style="font-weight:500;" color="orange" overlap content=" Most Popular ">
                                    <v-sheet @click="toggle" height="135" width="150" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <div class="text-center text-overline">
                                            Quarterly
                                        </div>
                            
                                        <div class="text-center">
                                           <span class="text-caption font-weight-bold" :class="active ? 'cyan--text text--lighten-2' : 'secondary--text'">SAVE 25%</span>
                                        </div>

                                        <div class="text-center text-h6 pa-1 pb-0 font-weight-bold" v-if="$store.getters.userDetails.country == 'IN'">
                                           &#8377; 1119.00
                                        </div>
                                        <div class="text-center text-h6 pa-1 pb-0 font-weight-bold" v-else>
                                           $44.99
                                        </div>
                                        
                                        <div class="text-center text-caption text-decoration-line-through pb-1" :class="active ? '' : 'grey--text'" v-if="$store.getters.userDetails.country == 'IN'">
                                             &#8377; 1497.00
                                        </div>
                                        <div class="text-center text-caption text-decoration-line-through pb-1" :class="active ? '' : 'grey--text'" v-else>
                                             $59.97
                                        </div>
                                        <!-- <div class="text-center text-caption">
                                            +3 connects*
                                        </div> -->
                                    </v-sheet>
                                    </v-badge>
                                </v-item>
                            </v-col>

                            <v-col cols="6" class="d-flex align-center justify-center">
                                <v-item v-slot="{ active, toggle }" value="S">
                                    <v-sheet @click="toggle" height="135" width="150" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <div class="text-center text-overline">
                                            Semi-Annual
                                        </div>

                                        <div class="text-center">
                                           <span class="text-caption font-weight-bold" :class="active ? 'cyan--text text--lighten-2' : 'secondary--text'">SAVE 40%</span>
                                        </div>
                                        
                                        <div class="text-center text-h6 pa-1 pb-0 font-weight-bold" v-if="$store.getters.userDetails.country == 'IN'">
                                           &#8377; 1799.00
                                        </div>
                                        <div class="text-center text-h6 pa-1 pb-0 font-weight-bold" v-else>
                                           $71.99
                                        </div>
                                        
                                        <div class="text-center text-caption text-decoration-line-through pb-1" :class="active ? '' : 'grey--text'" v-if="$store.getters.userDetails.country == 'IN'">
                                             &#8377; 2994.00
                                        </div>
                                        <div class="text-center text-caption text-decoration-line-through pb-1" :class="active ? '' : 'grey--text'" v-else>
                                             $119.94
                                        </div>

                                        <!-- <div class="text-center text-caption">
                                            +4  connects*
                                        </div> -->
                                    </v-sheet>
                                </v-item>
                            </v-col>

                            <v-col cols="6" class="d-flex align-center justify-center">

            
                              <v-item v-slot="{ active, toggle }" value="A">
                                    <v-sheet @click="toggle" height="135" width="150" class="pa-2 rounded-lg mild-shadow clickable" :color="active ? 'primary' : ''" :dark="active ? true : false">
                                        <div class="text-center text-overline">
                                            Annual
                                        </div>
                                        <div class="text-center">
                                            <span class="text-caption font-weight-bold" :class="active ? 'cyan--text text--lighten-2' : 'secondary--text'">SAVE 50%</span>
                                           
                                        </div>
                                        
                                        <div class="text-center text-h6 pa-1 pb-0 font-weight-bold" v-if="$store.getters.userDetails.country == 'IN'">
                                           &#8377; 2999.00
                                        </div>
                                        <div class="text-center text-h6 pa-1 pb-0 font-weight-bold" v-else>
                                           $119.99
                                        </div>
                                        
                                        <div class="text-center text-caption text-decoration-line-through pb-1" :class="active ? '' : 'grey--text'" v-if="$store.getters.userDetails.country == 'IN'">
                                             &#8377; 5988.00
                                        </div>
                                        <div class="text-center text-caption text-decoration-line-through pb-1" :class="active ? '' : 'grey--text'" v-else>
                                             $239.88
                                        </div>
                                        <!-- <div class="text-center text-caption">
                                            +5 connects*
                                        </div> -->
                                    </v-sheet>
                                </v-item>
                            </v-col>

                        </v-row>
                    </v-item-group>

                    <v-row>
                        <v-col>
                            <!-- <div class="grey--text text-caption">* One time credit</div> -->
                            <div class="text-caption pt-4"><b>Note:</b> All prices are listed in USD and will be converted to the relevant local currency during checkout.</div>
                        </v-col>
                       
                    </v-row>

        </v-card-text>
        <v-card-actions>
 
            <v-btn small text color="grey" @click.native="close">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="pl-8" @click="checkOut" :loading="submitting">NEXT <v-icon right>mdi-chevron-right</v-icon></v-btn>
                        
        </v-card-actions>
    </v-card>

          

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-dialog>
</template>

<script>
import axios from "axios";
import Bugsnag from '@bugsnag/js'
export default {
    name: 'PremiumCheckout',

    data() {
        return {
            showSubscribe: false,
            selectedPlan: 'Q',
            selectedValue:'19.99',
            selectedPath: 'premium-membership',

            submitting: false,
            valid: true,
            snackbar: false,
            snackbar_text: '',
            complete: false,
            stripeError: '',
            promoCode: '',
            promoCodeId: '',

            stripe: '',
            elements: '',
            card: '',
            intentToken: '',
            addPaymentStatus: 0,
            addPaymentStatusError: '',
            paymentElement: '',
            paymentElementComplete: false,
            paymentElementReady: false,

            cardNumber: '',
            cardExpiry: '',
            cardCvc: '',

            cardNumberComplete: false,
            cardExpiryComplete: false,
            cardCvcComplete: false,

            showCouponDialog: false,
            validatingCoupon: false,

            amtMonth: 19.99,
            amtQuarter: 44.99,
            amtSemiannual: 71.99,
            amtAnnual: 119.99,


        }
    },

    /* watch: {
        '$store.getters.premiumPop': function (val) {
            if (val) {
                setTimeout(() => this.configureNewStripe(), 500);
            }
        }
    }, */

    methods: {
        close() {
            this.$cookie.set("pm_premium_prompt_last_seen", this.$moment().format('M/D/YYYY'), 9999);
            this.$store.dispatch('showPremiumPop', false);
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("Cancel Button Clicked");
            }
        },

        checkOut()
        {
            //this.submitting = true;
            let plan = this.selectedPlan;

            if (this.selectedPlan == 'A') {
                this.selectedValue = this.amtAnnual;
                this.selectedPath = 'premium-membership-a';
            } else if (this.selectedPlan == 'S') {
                this.selectedValue = this.amtSemiannual;
                this.selectedPath = 'premium-membership-s';
            } else if (this.selectedPlan == 'Q') {
                this.selectedValue = this.amtQuarter;
                this.selectedPath = 'premium-membership-q';
            } else {
                this.selectedValue = this.amtMonth;
                this.selectedPath = 'premium-membership';
            }

            this.$cookie.set('pm_selected_plan', this.selectedPlan, 1);
            this.$cookie.set('pm_selected_value', this.selectedValue, 1);
            this.$cookie.set('pm_selected_path', this.selectedPath, 1);

            //Get the fs account id

            

            var s =
            {
                //Reset the cart session  to remove everything added to the cart previously.
                'reset':true,
                "paymentContact":{  
                    "email":this.$store.getters.userDetails.email,
                    "country":this.$store.getters.userDetails.country || 'US'
                },
                //Define the product path(s) and the quantity to add.
                'products' : [
                    {
                        'path':this.selectedPath,
                        'quantity': 1
                    }
                ],
                'checkout': true,
                "tags":{  
                    "customer":this.$store.getters.userDetails.xid
                },
            }

            axios.post('/v1/fastspring/subscription-init').then((resp) => {
                     
                window.gtag('event', 'Initiate Subscription FastSpring', {
                event_category: 'Subscription',
                event_label: 'Premium Membership',
                value: this.selectedValue
            });
                   
            }).catch(err => {
                //console.log(err.response);
                Bugsnag.notify(err)

            });

         

            

            window.fastspring.builder.push(s);

 
        },
        
    }

}
</script>
